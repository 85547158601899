import { GET_ROOM, UPDATE_CHECKIN } from '../action-types'
import api from '../../common/api'
import { handleServerError } from '../../common/utils'

export const getRoom = id => async dispatch => {
  try {
    const res = await api.room.get(id)
    dispatch({ type: GET_ROOM })
    return {
      status: res.status,
      data: { ...res.data, emptyRoom: res.status === 202 }
    }
  } catch (error) {
    return handleServerError()
  }
}

export const updateCheckin = (eventId, calendarId) => async dispatch => {
  try {
    const res = await api.appointment.updateCheckin(eventId, calendarId)
    dispatch({ type: UPDATE_CHECKIN })
    return { status: res.status }
  } catch (error) {
    return { status: error.response.status }
  }
}
