import PropTypes from 'prop-types'
import { UppercaseButton } from './styled'

const Button = props => {
  const { children, type } = props
  return (
    <UppercaseButton type={type || 'primary'} size={'large'} {...props}>
      <span>{children}</span>
    </UppercaseButton>
  )
}
Button.propTypes = {
  type: PropTypes.string
}
export default Button
