import styled from 'styled-components'
import { Layout } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`

export const RoomTitle = styled.h3`
  text-align: center;
  margin-bottom: 0;
`

export const ActionContainer = styled.div`
  text-align: center;
`
