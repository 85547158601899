import { FormattedMessage } from 'react-intl'
import { CalendarOutlined, ClockCircleOutlined, TeamOutlined } from '@ant-design/icons'

import { formatDateTime } from 'src/common/utils'
import { ContentField, ContentFieldIcon, ContentFieldLabel, ContentWrapper, StyledCard } from './styled'

const AppointmentCard = props => {
  const { data } = props
  return (
    <StyledCard>
      <ContentWrapper>
        <ContentField>
          <ContentFieldIcon><CalendarOutlined /></ContentFieldIcon>
          <ContentFieldLabel>
            <strong>{data.summary}</strong>
          </ContentFieldLabel>
        </ContentField>
        <ContentField>
          <ContentFieldIcon><ClockCircleOutlined /></ContentFieldIcon>
          <ContentFieldLabel>
            <FormattedMessage
              id={'room.timeSlot'}
              values={{
                start: formatDateTime(data.start),
                end: formatDateTime(data.end)
              }}
            />
          </ContentFieldLabel>
        </ContentField>
        <ContentField>
          <ContentFieldIcon><TeamOutlined /></ContentFieldIcon>
          <ContentFieldLabel>
            {data.attendees?.join('\n')}
          </ContentFieldLabel>
        </ContentField>
      </ContentWrapper>
    </StyledCard>
  )
}
export default AppointmentCard