import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': '9D7B1F8A-F0CA-4C00-A29A-DDB602205958'
  }
})

const apis = {
  user: {
    authenticate: token => BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  room: {
    get: id => BE_API.get(`/rooms/${id}`)
  },
  appointment: {
    updateCheckin: (eventId, calendarId) => BE_API.patch(`/appointments/${eventId}/checkin`, { calendarId })
  }
}
export default apis