import { createIntl, createIntlCache } from 'react-intl'
import { notification } from 'antd'
import moment from 'moment'

import config from './config'
import { locale, messages } from './translations'

const intl = createIntl({ locale, messages }, createIntlCache())

export const handleServerError = error => {
  console.error(error)
  if (error.response?.status === 401) {
    window.location.href = config.LOGIN_URL
  } else {
    const status = error.response?.status
    const data = error.response?.data
    return notification.error({
      message: status
        ? intl.formatMessage({ id: 'error.title.specific' }, { statusCode: status })
        : intl.formatMessage({ id: 'error.title.generic' }),
      description: data?.message || intl.formatMessage({ id: 'error.description.generic' })
    })
  }
}

export const formatDateTime = (date, format) =>
  moment(date).format(format || 'HH:mm')

export const getShortName = (firstName, lastName) =>
  `${firstName} ${lastName.substring(0, 1)}.`
