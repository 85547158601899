import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Result } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'
import { getRoom, updateCheckin } from 'src/redux-store/actions/app-actions'

import Loader from 'src/components/Loader'
import Button from 'src/components/Button'
import EmptyRoom from 'src/components/EmptyRoom'
import AppointmentCard from 'src/components/AppointmentCard'
import { ActionContainer, RoomTitle } from 'src/common/styled'

const Room = props => {
  const { match, getRoomAction, updateCheckinAction } = props
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [roomId] = useState(match.params.id)
  const [data, setData] = useState({})
  const [hasCheckinError, setHasCheckinError] = useState(false)
  const [hasRoomError, setHasRoomError] = useState(false)

  const getRoom = useCallback(
    async id => {
      setIsLoading(true)
      const { status, data } = await getRoomAction(id)
      if (status === 204) setHasRoomError(true)
      setData(data)
      setIsLoading(false)
    },
    [getRoomAction]
  )

  useEffect(() => {
    getRoom(roomId)
  }, [roomId, getRoom])

  const updateCheckin = useCallback(async () => {
    setIsButtonLoading(true)
    const res = await updateCheckinAction(data.eventId, data.calendarId)

    if (res.status === 200)
      setData(prevData => ({ ...prevData, checkinDone: true }))
    if (res.status >= 400) setHasCheckinError(true)

    setIsButtonLoading(false)
  }, [data.eventId, data.calendarId, updateCheckinAction])

  const renderAction = useCallback(() => {
    if (data.isAttendee === false) {
      return <FormattedMessage id={'room.isNotAttendee'} />
    }
    if (data.checkinDone) {
      return (
        <Result
          status={'success'}
          title={<FormattedMessage id={'room.checkinDone'} />}
        />
      )
    }
    if (hasCheckinError) {
      return (
        <Result
          status={'error'}
          title={<FormattedMessage id={'room.checkinError.title'} />}
          subTitle={<FormattedMessage id={'room.checkinError.subtitle'} />}
        />
      )
    }

    return (
      <Button
        icon={<PlusOutlined />}
        loading={isButtonLoading}
        onClick={updateCheckin}>
        <FormattedMessage id={'room.button.checkin'} />
      </Button>
    )
  }, [isButtonLoading, data, hasCheckinError, updateCheckin])

  if (isLoading) return <Loader />
  if (hasRoomError)
    return (
      <Result
        status={'500'}
        title={<FormattedMessage id={'room.notFoundError'} />}
      />
    )
  return (
    <>
      <RoomTitle>{data.room}</RoomTitle>
      {data.emptyRoom ? (
        <EmptyRoom />
      ) : (
        <>
          {AppointmentCard({ data })}
          <ActionContainer>{renderAction()}</ActionContainer>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = {
  getRoomAction: getRoom,
  updateCheckinAction: updateCheckin
}
export default connect(() => ({}), mapDispatchToProps)(Room)
