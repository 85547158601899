import { FormattedMessage } from 'react-intl'
import { Result } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'

const EmptyRoom = props => {
  return (
    <Result
      status={'404'}
      subTitle={
        <FormattedMessage
          id={'emptyRoom.description'}
          values={{
            br: <br />,
            ul: msg => <ul>{msg}</ul>,
            li: msg => <li>{msg}</li>
          }}
        />
      }
      extra={
        <a href={'https://mylm.it/aiutosale'} target={'_blank'} rel={'noopener noreferrer'}>
          <Button icon={<InfoCircleOutlined />}>
            <FormattedMessage id={'emptyRoom.button.faq'} />
          </Button>
        </a>
      }
    />
  )
}
export default EmptyRoom
