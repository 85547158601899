import styled from 'styled-components'
import { colors } from 'src/common/constants'
import { Card } from 'antd'

export const StyledCard = styled(Card)`
  border-color: ${colors.grey2};
`
export const ContentWrapper = styled.div`
  display: grid;
  gap: 8px;
`
export const ContentField = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr;
  align-items: baseline;
`
export const ContentFieldIcon = styled.div`
  font-size: 20px;
  color: ${colors.main};
`
export const ContentFieldLabel = styled.div``