const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-roomcheckin-nprd-ping-6ryorx7kka-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080'
  },
  test: {
    LOGIN_URL: `https://test-lmit-roomcheckin-nprd-ping-6ryorx7kka-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-roomcheckin-api-6ryorx7kka-ew.a.run.app'
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-roomcheckin-prd-ping-fn2klqucpq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-roomcheckin-api-fn2klqucpq-ew.a.run.app'
  },
}

export default envConfig[env]